<template>
  <b-container class="payment-method-form-container">
    <b-form-row>
      <b-col cols="12" md="6">
        <b-form-group
          id="first-name-group"
          label="First Name:"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="minmax:1,20|required"
            name="First Name"
          >
            <b-form-input
              id="first-name-input"
              v-model="card.first_name"
              type="text"
              :state="!errors[0] ? null: false"
            />
            <b-form-invalid-feedback :state="!errors[0] ? null: false">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group
          id="last-name-group"
          label="Last Name:"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="minmax:1,20|required"
            name="Last Name"
          >
            <b-form-input
              id="last-name-input"
              v-model="card.last_name"
              type="text"
              :state="!errors[0] ? null: false"
            />
            <b-form-invalid-feedback :state="!errors[0] ? null: false">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col cols="12" md="6">
        <b-form-group
          id="number-group"
          label="Card Number:"
        >
          <validation-provider
            v-slot="{ errors }"
            :rules="editMode ? 'credit-number' : 'required|credit-number'"
            name="Card Number"
          >
            <template>
              <b-form-input
                id="number-input"
                v-model="cardNumber"
                v-cardformat:formatCardNumber
                class="form-control"
                name="cardNumber"
                autocomplete="cc-number"
                :placeholder="editMode ? hiddenNumber : '#### #### #### ####'"
                :state="!errors[0] ? null: false"
              />
              <b-form-invalid-feedback :state="!errors[0] ? null: false">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </template>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="6" md="3">
        <b-form-group
          id="expiration-group"
          label="Expiration:"
        >
          <validation-provider
            v-slot="{ errors }"
            :rules="editMode ? 'expiration' : 'required|expiration'"
            name="Expiration"
          >
            <template>
              <b-form-input
                id="exp-input"
                v-model="expiration"
                v-cardformat:formatCardExpiry
                name="expiration"
                autocomplete="cc-exp"
                :placeholder="editMode ? '** / **' : 'MM / YY'"
              />
              <b-form-invalid-feedback :state="!errors[0] ? null: false">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </template>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="6" md="3">
        <b-form-group
          id="cvc-group"
          label="CVC:"
        >
          <validation-provider
            v-slot="{ errors }"
            :rules="cvcValidationRules"
            name="CVC"
          >
            <template>
              <b-form-input
                id="cvc-input"
                v-model="card.cvc"
                v-cardformat:formatCardCVC
                name="cvc"
                autocomplete="cc-csc"
                :placeholder="editMode ? '***' : ''"
                :maxlength="cvcLength"
              />
              <b-form-invalid-feedback :state="!errors[0] ? null : false">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </template>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-form-row>
  </b-container>
</template>

<script>

  import { extend, ValidationProvider } from 'vee-validate'
  import validation from 'vue-credit-card-validation/src/validation'
  import cardFormatUtils from 'vue-credit-card-validation/src/utils'

  extend('credit-number', {
    validate(value) {
      return validation.validateCardNumber(value)
    },
  })
  extend('expiration', {
    validate(value) {
      return validation.validateCardExpiry(value)
    },
  })
  extend('cvc', {
    validate(value) {
      return validation.validateCardCVC(value)
    },
  })
  extend('amexcvc', {
    params: ['target'],
    validate (value, { target }) {
      const card = cardFormatUtils.cardFromNumber(target)

      if (card && card.type === 'amex') {
        return value.length === 4
      }
      return true
    },
    message: 'Use 4 digits for Amex',
  })

  export default {
    name: 'CreditCardForm',
    components: {
      ValidationProvider,
    },
    props: {
      editCard: null,
    },
    data() {
      return this.initialData()
    },
    computed: {
      hiddenNumber() {
        return `**** **** **** ${this.card.last4}`
      },
      editMode() {
        return !!this.editCard
      },
      cvcLength() {
        const card = cardFormatUtils.cardFromNumber(this.cardNumber)

        if (card && card.type === 'amex') {
          return 4
        }
        return 3
      },
      cvcValidationRules () {
        const rules = {
          cvc: true,
        }

        if (!this.editMode) {
          rules.required = true
          rules.amexcvc = this.cardNumber
        }

        return rules
      },
    },
    watch: {
      expiration(){
        this.formatExpiration()
      },
      cardNumber() {
        if (this.cardNumber) {
          this.card.number = this.cardNumber.replace(/\s/g, '')
        }
      },
      editCard() {
        if (this.editCard !== null) {
          this.card = { ...this.card, ...this.editCard }
        } else {
          Object.assign(this.$data, this.initialData())
        }
      },
      card: {
        deep: true,
        handler() {
          this.formatExpiration()
          this.$emit('changed', this.card)
        },
      },
    },
    mounted() {
      if(this.editCard !== null) {
        this.card = { ...this.card, ...this.editCard }
      }
    },
    methods: {
      formatExpiration(){
        if (this.expiration) {
          const exp = this.expiration.replace(/\s+/g, '').split('/')
          this.card.exp_month = exp[0]
          if (exp[1] && exp[1].length == 2) {
            this.card.exp_year = '20' + exp[1]
          } else if(exp[1]) {
            this.card.exp_year = exp[1]
          }
        }
      },
      initialData(){
        return {
          expiration: null,
          cardNumber: null,
          card: {
            number: null,
            exp_month: null,
            exp_year: null,
            cvc: null,
            first_name: null,
            last_name: null,
          },
        }
      },
    },
  }
</script>

<style scoped>

</style>
